<template>
  <div class="singersimi">
    <div class="item"
         v-for="(item, index) in artists"
         :key="index">
      <img v-lazy="item.img1v1Url"
           @click="itemClick(item)">
      <div class="text_hidden">{{ item.name }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'singerSimi',
  props: {
    artists: {
      type: Array,
      default () {
        return []
      }
    }
  },
  methods: {
    itemClick (item) {
      window.sessionStorage.setItem('singerId', item.id)
      this.$emit('simiClick')
    }
  }
}
</script>

<style lang="less" scoped>
.item {
  display: inline-block;
  width: 20%;
  margin-top: 20px;
  text-align: center;
  .text_hidden {
    margin-top: 10px;
  }
  img {
    width: 80%;
    cursor: pointer;
    border-radius: 20px;
  }
  div {
    margin: 5px 0;
    text-align: left;
    text-indent: 2em;
  }
}

@media screen and (max-width: 479px) {
  .item {
    width: 20vw;
    margin: 0 0 2vw 0.5vw;
    text-align: left;
    .text_hidden {
      margin-top: 1vw;
      margin-left: -4vw;
      font-size: 10px;
    }
    img {
      border-radius: 15px;
    }
  }
}
</style>
