<template>
  <div class='singerDetail'>
    <div class="header">
      <img :src="singer.cover">
      <div class="info">
        <h1><span>歌手</span>{{ singer.name }}</h1>
        <div>
          <span>歌曲: </span> <i>{{ singer.musicSize}}</i>
          <span style="margin-left:40px">专辑: </span> <i>{{ singer.albumSize}}</i>
          <span style="margin-left:40px">MV: </span> <i>{{ singer.mvSize}}</i>
        </div>

        <div>
          <div class="briefDesc">{{"简介:"+"&emsp;"+ singer.briefDesc}}</div>
        </div>
      </div>
    </div>
    <div>
      <el-tabs v-model="activeName">
        <el-tab-pane label="歌曲"
                     name="song">
          <singer-song :song="song" />
        </el-tab-pane>
        <el-tab-pane label="专辑"
                     name="album">
          <singer-album :ablum="ablum" />
        </el-tab-pane>
        <el-tab-pane label="MV"
                     name="mv">
          <singer-mv :mvs="mv" />
        </el-tab-pane>
        <el-tab-pane label="歌手详情"
                     name="detail">
          <singer-detaild :detail="detail" />
        </el-tab-pane>
        <el-tab-pane label="相似歌手"
                     name="simi">
          <singer-simi :artists="artists" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import {
  singerSongAPI,
  singerDescAPI,
  singerAlbumAPI,
  singerMvAPI,
  singerDetailAPI,
  singerSimiArtistAPI
} from '@/api/api'

import singerSong from './SingerSong'
import singerAlbum from './SingerAlbum'
import singerMv from './SingerMv'
import singerDetaild from './SingerDetail_detail.vue'
import singerSimi from './SingerSimi'
export default {
  name: 'singerDetail',
  components: {
    singerSong,
    singerMv,
    singerAlbum,
    singerDetaild,
    singerSimi
  },
  data () {
    return {
      activeName: 'song',
      id: '',
      singer: {},
      detail: {},
      artists: [],
      mv: [],
      ablum: [],
      song: []
    }
  },
  created () {
    const singerId = this.$route.query.id
    this.id = singerId
    this.getSingerData(this.id)
  },
  methods: {
    async getSingerData (id) {
      const {
        data: { data: detail }
      } = await singerDetailAPI({ id })
      this.singer = detail.artist
      const {
        data: { songs: song }
      } = await singerSongAPI({ id })
      this.song = song
      const { data: ablum } = await singerAlbumAPI({ id })
      this.ablum = ablum.hotAlbums
      const { data: mv } = await singerMvAPI({ id })
      this.mv = mv.mvs.splice(0, 12)
      const { data: desc } = await singerDescAPI({ id })
      this.detail = desc
      const { data: artist } = await singerSimiArtistAPI({ id })
      this.artists = artist.artists
    }
  }
}
</script>

<style scoped lang="less">
.header {
  margin-top: 30px;
  overflow: hidden;
  img {
    float: left;
    width: 300px;
    border-radius: 30px;
    overflow: hidden;
  }
  .info {
    float: left;
    margin-left: 50px;
    h1 {
      font-size: 30px;
      span {
        padding: 3px 15px;
        font-size: 20px;
        margin: 0px 20px 10px 0;
        color: #ff0000;
        border: 1px solid #ff0000;
        border-radius: 10px;
      }
    }
    div {
      margin: 20px 0;
      .briefDesc {
        width: 600px;
        color: rgb(110, 110, 110);
        line-height: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 7;
      }
    }
  }
}

@media screen and (max-width: 479px) {
  .header {
    margin-top: 3vw;
    img {
      width: 40vw;
      margin-left: 23vw;
      border-radius: 15px;
    }
    .info {
      margin-top: 2vw;
      margin-left: 2vw;
      h1 {
        display: flex;
        justify-content: center;
        font-size: 20px;
        span {
          padding: 0.5vw 2vw;
          font-size: 16px;
          margin: 0px 5vw 2vw 0;
          font-size: 14px;
        }
      }
      div {
        margin: 1vw 0 2vw 0;
        .briefDesc {
          width: 82vw;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
}
</style>
