<template>
  <div class="album">
    <div class="item"
         v-for="(item, index) in ablum"
         :key="index">
      <div class="image"><img v-lazy="item.picUrl"
             @click="toAlbum(item.id)" />
        <div class="albumname">{{item.name}}</div>
        <div class="albutime">{{item.publishTime|LocaleDateString}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'singerAlbum',
  props: {
    ablum: {
      type: Array,
      default () {
        return []
      }
    }
  },
  methods: {
    toAlbum (id) {
      this.$router.push(`/album?id=${id}`)
    }
  }
}
</script>

<style lang="less" scoped>
.album {
  display: flex;
  flex-wrap: wrap;
  .item {
    display: flex;
    flex-direction: row;
    width: 25%;
    margin-bottom: 30px;
    .image {
      width: 80%;
      img {
        width: 100%;
        border-radius: 10px;
        cursor: pointer;
      }
    }
  }
}
.albumname {
  margin: 10px 10px;
  font-size: 18px;
}
.albutime {
  margin-left: 10px;
  font-size: 14px;
  color: #a7a6a6;
}

@media screen and (max-width: 479px) {
  .album {
    justify-content: space-evenly;
    .item {
      width: 24vw;
      margin: 0 0 3vw 2vw;
      .image {
        width: 100%;
      }
    }
  }
  .albumname {
    margin: 1.5vw;
    font-size: 13px;
  }
  .albutime {
    margin-left: 2vw;
    font-size: 12px;
  }
}
</style>
