<template>
  <div class="singer-song"
       v-loading="loading">
    <el-table :data="songsList"
              stripe
              style="width: 100vw"
              @row-dblclick="play">

      <el-table-column type="index"
                       v-if="columnshow"></el-table-column>

      <el-table-column width="100"
                       v-if="columnshow">
        <template slot-scope="scope">
          <div class="img-wrap">
            <p class="iconfont icon-play"
               @click="play(scope.row)"></p>
          </div>
        </template>
      </el-table-column>

      <el-table-column :width="songwidth"
                       label="歌曲"
                       prop="songName">
        <template slot-scope="scope">
          <span @click="play(scope.row)"
                style="cursor: pointer;color:#2980b9">{{scope.row.songName}}</span>
        </template>
      </el-table-column>

      <el-table-column :width="mvwidth">
        <template slot-scope="scope">
          <i v-if="scope.row.mv!==0"
             class="iconfont icon-MV iconmv"
             @click="tomv(scope.row.mv)"></i>
        </template>
      </el-table-column>

      <el-table-column prop="publishTime"
                       label="发布日期"
                       :width="pbwidth"></el-table-column>

      <el-table-column prop="album"
                       label="专辑">
        <template slot-scope="scope">
          <span style="cursor:pointer;color:#2980b9;"
                @click="toAlbum(scope.row.albumId)">{{scope.row.album}}</span>
        </template>
      </el-table-column>

      <el-table-column prop="duration"
                       label="时长"
                       :width="dtwidth"></el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data () {
    return {
      songsList: [],
      songUrl: '',
      loading: true,
      columnshow: true,
      songwidth: 150,
      mvwidth: 100,
      pbwidth: '',
      dtwidth: 100
    }
  },
  props: {
    song: {
      type: Array,
      default () {
        return []
      }
    }
  },
  watch: {
    song: function () {
      this.onLoad()
    }
  },
  computed: {
    musicQueue () {
      return this.$store.state.musicQueue
    }
  },
  methods: {
    onLoad () {
      for (const item of this.song) {
        const duration = this.$options.filters.formatDuration(item.dt)
        const publishTime = this.$options.filters.LocaleDateString(
          item.publishTime
        )
        const song = {
          id: item.id,
          songName: item.name,
          publishTime,
          album: item.al.name,
          albumId: item.al.id,
          duration,
          mv: item.mv,
          artistInfo: item.ar,
          imgUrl: item.al.picUrl
        }
        this.songsList.push(song)
      }
      this.loading = false
    },
    tomv (id) {
      this.$router.push(`/mvdetail?id=${id}`)
    },
    toArtist (id) {
      this.$router.push(`/artist?artistId=${id}`)
    },
    toAlbum (id) {
      this.$router.push(`/album?id=${id}`)
    },
    play (item) {
      const musicInfo = {
        imgUrl: item.imgUrl,
        artistInfo: item.artistInfo,
        songName: item.songName,
        id: item.id,
        duration: item.duration
      }
      this.playMusic(item, musicInfo)
    }
  },
  mounted () {
    if (document.documentElement.clientWidth < 600) {
      this.columnshow = false
      this.songwidth = 78
      this.mvwidth = 30
      this.pbwidth = 98
      this.dtwidth = 50
    }
  }
}
</script>

<style scoped>
.singer-song {
  margin-top: 50px;
}

.img-wrap {
  width: 40px;
  height: 40px;
  position: relative;
}

.img-wrap p::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 30px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ff0000 !important;
  font-size: 14px;
  cursor: pointer;
}
.el-table {
  margin-top: -60px;
}
.iconmv {
  color: #ff0000;
  font-size: 20px;
  cursor: pointer;
}

@media screen and (max-width: 479px) {
  .singer-song {
    margin-top: 50px;
  }
}
</style>
