<template>
  <div class='singerMv'>
    <div class="item"
         v-for="item in mvs"
         :key="item.id">
      <img v-lazy="item.imgurl"
           @click="mvClick(item.id)">
      <span class="playcount"><i class="iconfont icon-caret-right"></i>{{ item.playCount | ellipsisPlayVolume }}</span>
      <span class="playduration">{{ item.duration | formatDuration }}</span>
      <div class="name">{{ item.name }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'singerMv',
  components: {},
  props: {
    mvs: {
      type: Array,
      default () {
        return []
      }
    }
  },
  methods: {
    mvClick (id) {
      this.$router.push(`/mvdetail?id=${id}`)
    }
  }
}
</script>

<style scoped lang="less">
.singerMv {
  display: flex;
  flex-wrap: wrap;
  .item {
    width: 30%;
    margin-top: 20px;
    position: relative;
    img {
      width: 90%;
      border-radius: 20px;
      cursor: pointer;
    }
    .name {
      margin: 10px 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 300px;
    }
    .playcount {
      position: absolute;
      top: 5px;
      right: 50px;
      color: #fff;
    }
    .playduration {
      position: absolute;
      bottom: 40px;
      right: 50px;
      color: #fff;
    }
  }
}

@media screen and (max-width: 479px) {
  .singerMv {
    justify-content: space-evenly;
    .item {
      width: 38vw;
      margin-top: 2vw;
      img {
        width: 100%;
        border-radius: 15px;
      }
      .name {
        margin: 2vw;
        font-size: 14px;
        width: 36vw;
      }
      .playcount {
        right: 2vw;
        font-size: 10px;
      }
      .playduration {
        position: absolute;
        bottom: 9vw;
        right: 2vw;
        font-size: 10px;
      }
    }
  }
}
</style>
